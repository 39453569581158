import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getDateFromString(dateString: string | undefined) {
  if (dateString === null || dateString === undefined) {
    return undefined;
  }

  return new Date(Date.parse(dateString));
}

export function base64ToBlob(base64: string) {
  // Check if the base64 string is a data URL
  const base64WithoutPrefix = base64.includes("base64,")
    ? base64.split("base64,")[1]
    : base64;

  // Decode base64 string
  const binaryString = atob(base64WithoutPrefix);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return new Blob([bytes], { type: "image/jpeg" });
}
